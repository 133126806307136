// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  staging: 'dev',
  stripe_pk : "pk_test_2I3K6xxbtf4JB5zG5awKOTRg",
  firebaseConfig: {
    apiKey: "AIzaSyAiKYP2Xrx7XQLqXdRPVnTRnkCFFk6XapE",    
    authDomain: "auth.parkbewilligung.ch",
    databaseURL: "https://parkbewilligung-4455b.firebaseio.com",
    projectId: "parkbewilligung-4455b",
    storageBucket: "parkbewilligung-4455b.appspot.com",
    messagingSenderId: "240289466606",
    appId: "1:240289466606:web:ac0284d9014e88a2b04bff",
    measurementId: "G-1K07RNKDPT"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
